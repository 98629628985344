// Packages
import React from "react";
import { graphql } from "gatsby";
import type { IGatsbyImageData } from "gatsby-plugin-image";
import { BeakerIcon, CurrencyEuroIcon, UsersIcon, FastForwardIcon } from "@heroicons/react/outline";

// Types
import type { SiteMetadata, GatsbyImagePropsMeta, Location } from "~types/Gatsby";
import type { Product } from "~types/Shopify";

// Components
import ProductCard from "~components/shop/ProductCard";
import P from "~components/typography/P";
import H2 from "~components/typography/H2";
import Breadcrumb from "~components/common/Bradcrumb";
import Hero from "~components/sections/Hero";
import Ul from "~components/typography/Ul";
import H1 from "~components/typography/H1";
import Container from "~components/common/Container";
import Grid from "~components/common/Grid";
import FeatureIcon from "~components/features/FeatureIcon";
import Layout from "~components/layouts/Layout";
import Button from "~components/common/Button";
import SubTitle from "~components/typography/SubTitle";
import Alert from "~components/common/Alert";
import Cta from "~components/sections/Cta";
import ElipseImageComposition from "~components/sections/elements/ElipseImageComposition";
import Testmonials from "~components/sections/Testmonials";
import { testmonials } from "~data/testmonials";

interface DataProps {
	site: {
		siteMetadata: SiteMetadata;
	};
	seoImage: {
		facebook: GatsbyImagePropsMeta;
		twitter: GatsbyImagePropsMeta;
	};
	products: {
		nodes: Product[];
	};
	imageLevelUp: IGatsbyImageData;
}

// Also change this in graphql!
const PRODUCT_TYPE = "Speed Reading";
const PRODUCT_ANCHOR = "/workshops/speed-reading/#workshops";

export default function Page({ data, location }: { data: DataProps; location: Location }) {
	const { products } = data;
	return (
		<Layout
			location={location}
			title="Speed Reading Workshops"
			desc=""
			// imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			// imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			// imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			// imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
		>
			<Breadcrumb
				pages={[
					{
						name: "Workshops",
						to: "/workshops/",
					},
					{
						name: PRODUCT_TYPE,
						to: "/workshops/speed-reading/",
						current: true,
					},
				]}
			/>
			<Hero image={data.imageLevelUp}>
				<SubTitle className="mb-2">Workshop</SubTitle>
				<H1 display className="mb-4">
					{PRODUCT_TYPE}
				</H1>
				<P className="mb-4">
					Viele unserer Kursteilnehmer verdoppeln ihre Lesegeschwindigkeit innerhalb weniger Tage und haben diese
					Fähigkeit dann für den Rest ihres Lebens
				</P>
				<Ul
					items={[
						"Mehr Erreichen",
						"Mehr Zeit und weniger Stress",
						"Bessere Konzentration",
						"Besseres Gedächtnis",
						"Logisches Denken",
						"Mehr Entspannung",
					]}
					className="mb-10"
					pretty
				></Ul>
				{/* <Button anchor={PRODUCT_ANCHOR} text="Termine finden" target="_blank" rel="noopener noreferrer" /> */}
			</Hero>
			{/* <Container id="workshops">
				<div className="text-center">
					<SubTitle className="mb-2">Workshop buchen</SubTitle>
					<H2 display className="mb-16">
						Unser aktuelles Workshop-Angebot
					</H2>
				</div>

				{products.nodes.length > 0 ? (
					<Grid cols={3}>
						{products.nodes.map((product) => (
							<ProductCard key={product.title} product={product} />
						))}
					</Grid>
				) : (
					<Alert type="information" title="Keine Workshops gefunden" message={`Aktuell werden weitere ${PRODUCT_TYPE} Kurse geplant. Komme gerne später wieder oder buche unseren Newsletter für weitere Informationen.`} />
				)}
			</Container> */}

			<Testmonials data={testmonials} />

			<Container>
				<div>
					<SubTitle className="mb-2">Beschreibung</SubTitle>
					<H2 display className="mb-4">
						Deine Vorteile
					</H2>
				</div>
				<Grid cols={2}>
					<div>
						<P className="mb-3">
							Für alle, die ihre mentale Leistungs- und Konzentrationsfähigkeit verbessern möchten oder im Alltag mit zu
							vielen Informationen kämpfen. Und natürlich für alle, die mehr Informationen und Wissen in kürzester Zeit
							aufnehmen und abspeichern möchten. Alle Themen sind auf eine einfache Integration in den Alltag ausgelegt
							und ich trainiere häufig Akademiker und High-Performer, die anspruchsvolle Berufe ausüben. Bei jeder Form
							von Gehirntraining ist die Regelmäßigkeit wichtiger als die Intensität.
						</P>
						<P className="mb-3">
							Ob E-Mails, Memos, Dokumente, Zeitschriften oder Fachliteratur: Schreibtisch-Arbeitende lesen etwa 3
							Stunden pro Arbeitstag. Das sind etwa 660 Stunden im Jahr oder 82,5 volle 8-Stunden-Arbeitstage. Stell Dir
							vor:
						</P>
						<Ul
							items={[
								"Stell dir vor, wie sich Dein Leben verändern würde, wenn Du Deine Lesegeschwindigkeit dauerhaft um nur 10% steigern könntest. Du kannst jeden Tag eine Viertelstunde länger Pause machen, früher nach Hause gehen oder mehr lernen und erreichen.",
								"Jetzt überlege Dir, wie das bei 30% Steigerung wäre: Du könntest 25 komplette Arbeitstage pro Jahr einsparen! Deine Produktivität würde in ungeahnte Höhen schießen und Du hätest einen riesigen Wettbewerbsvorteil.",
							]}
							className="mb-3"
						/>
						<P>
							Mit unseren Trainings sind sogar Steigerungen von 50-70% möglich, und das sogar bei komplexen Fachtexten!
							Bei leichten Texten wirst Du Dein Tempo locker verdoppeln oder gar verdreifachen. Rechne selbst nach, wie
							hoch Dein ROI (Return on Investment) sein wird, wenn Du ab sofort jedes Jahr mehrere hundert Stunden
							Arbeitszeit einsparst und vielfach mehr erreichst. Und was würdest Du mit dieser freigewonnenen Zeit
							anstellen? Mehr arbeiten und mehr Geld verdienen? Weniger arbeiten und mehr Zeit mit Freunden und Familie
							verbringen? Mehr Pausen einbauen und entspannter arbeiten? Erfolgreicher Studieren oder sich weiterbilden
							für mehr Erfolg? Was es auch sein mag, in jedem Fall bleibt dir mehr Zeit zum gemütlichen Genusslesen.
						</P>
					</div>

					<Grid cols={1}>
						{[
							{
								icon: FastForwardIcon,
								title: "Schneller lesen",
								description:
									"Einfache Texte rund 100% und Fachtexte ca. 75% schneller in der gleichen oder der Hälfte der Zeit.",
								color: "blue" as const,
							},
							{
								icon: CurrencyEuroIcon,
								title: "Geld-zurück-Garantie",
								description:
									"Solltest Du mit den Ergebnissen, dem Trainer oder dem Kurs-Format nicht zufrieden sein, zahlen wir Dir Dein Geld einfach zurück*.",
								color: "blue" as const,
							},
							{
								icon: BeakerIcon,
								title: "Nach aktuellen Leseforschungen",
								description:
									"Du übst in einem Raum mit dem Trainer und anderen angehenden Speedreadern und erlebst gemeinsam die anfänglichen Zweifel, dann die Fortschritte und schließlich die Begeisterung.",
								color: "blue" as const,
							},
							{
								icon: UsersIcon,
								title: "Netzwerken",
								description:
									"Verbringe 2 Tage Zeit mit weiteren spannenden Persönlichkeiten. In unseren Seminaren sind schon so manche Freundschaften und auch Geschäftsverbindungen entstanden.",
								color: "blue" as const,
							},
						].map((feature) => {
							return (
								<FeatureIcon
									title={feature.title}
									description={feature.description}
									icon={feature.icon}
									color={feature.color}
									align="left"
									key={feature.title}
								/>
							);
						})}
					</Grid>
				</Grid>
			</Container>

			{/* <Cta
				title={`Jetzt ${PRODUCT_TYPE} buchen!`}
				description="Verpasse nicht unseren nächsten Live-Workshop und buche einen unserer limitierten Plätze."
				button={{
					text: "Jetzt Termine finden",
					anchor: PRODUCT_ANCHOR,
				}}
			/> */}
		</Layout>
	);
}

export const query = graphql`
	{
		site {
			siteMetadata {
				siteUrl
			}
		}
		imageLevelUp: file(relativePath: { eq: "pages/workshops/speed-reading/level-up.png" }) {
			childImageSharp {
				gatsbyImageData
			}
		}
	}
`;
